.diff-word {
  padding-left: 1px;
  padding-right: 1px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 4px;
}

.diff-word-delete {
  background-color: #ff957e;
  text-decoration: line-through;
}

.diff-word-add {
  background-color: rgba(0, 194, 129, .4);
}
