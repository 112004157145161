

.cursor {
  background-color: white;
  width: 0.15rem;
  height: 1rem;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% {
      background-color: transparent;
  }
  50% {
      background-color: white;
  }
}

@keyframes blink {
  0%, 100% {
      background-color: transparent;
  }
  50% {
      background-color: white;
  }
}