.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: relative;
  width: 300px; /* Adjust the size as needed */
  padding: 20px;
  background-color: #141515; /* Matches the dark background */
  color: #FFFFFF; /* Ensures text is visible */
  border: 2px solid #9DA3A4; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: for a shadow effect */
}

.container::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid #9DA3A4; /* Matches outer border color */
  border-radius: 10px;
  pointer-events: none; /* Ensures the pseudo-element doesn’t interfere with clicking */
}